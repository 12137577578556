import Img1 from '../assets/imgs/communityImg1.jpeg';
import Img2 from '../assets/imgs/communityImg2.jpeg';
import Img3 from '../assets/imgs/communityImg3.jpeg';
import Img4 from '../assets/imgs/communityImg4.jpeg';
import Img5 from '../assets/imgs/communityImg5.jpeg';
import Img6 from '../assets/imgs/communityImg6.jpeg';
import Img7 from '../assets/imgs/communityImg7.jpeg';
import Img8 from '../assets/imgs/communityImg8.jpeg';


export const communityData = [Img1, Img2, Img3, Img4, Img5, Img6, Img7, Img8]
