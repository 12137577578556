export const faqData = [
  {
    question: "How will be hide nft treasures in our interactive maze style museum?",
    answer: "We have several methods including but not limited to Steganography."
  },
  {
    question: "Prereveal",
    answer: "Your art won't be visible for anyone to see until all of them are spread out to the community to ensure" +
      " Noone can look on chain for the better pieces in the collection allowing fair distribution."
  },
  {
    question: `What is "imm"?`,
    answer: "Several times we mention IMM or BAE, these are terms for a new concept blockchain art experience."
  },
  {
    question: "How many can you mint?",
    answer: "To limit abuse we have restricted each wallet to 30 mints max with 20 per tx."
  },
]
